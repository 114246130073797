* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 100vh;
  background-color: #e5edf3a8;
  overflow-y: hidden;
  overflow-x: hidden;
}

.login-form-container {
  padding: 2.5rem;
  /* Increased padding for a larger container */
  max-width: 450px;
  /* Increased max-width */
  border-radius: 8px;
  /* Rounded corners */
}

.login-form-label {
  display: block;
  margin-bottom: 0.5rem;
  color: #000000;
}

.login-form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid black;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
  margin-bottom: 1rem;
  /* Added margin-bottom for spacing */
}

.login-form-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.25);
}

.login-form-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
  /* Added margin-top for button spacing */
}

.login-form-button:hover {
  background-color: #0056b3;
  color: #fff;
}

.login-form-button:focus {
  outline: none;
}

.forgot-password {
  text-align: center;
  margin-top: 1rem;
}

.forgot-password-link {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.forgot-password-link:hover {
  color: #0056b3;
}

.logo {
  display: block;
  margin: 0 auto;
  /* Center the logo */
  width: 160px;
  /* Adjust size as needed */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .login-form-container {
    padding: 1.5rem;
    /* Adjust padding for medium screens */
    max-width: 90%;
    /* Allow form to take more width */
    margin-bottom: 100px;
  }

  .login-form-label {
    font-size: 0.95rem;
    /* Slightly smaller label font size */
  }

  .login-form-input {
    font-size: 0.95rem;
    /* Slightly smaller input font size */
  }

  .login-form-button {
    font-size: 0.95rem;
    /* Adjust button font size */
  }

  .forgot-password-link {
    font-size: 0.85rem;
    /* Adjust font size for the link */
    text-align: left;
    /* Align to the left on smaller screens */
  }
}

@media (max-width: 480px) {
  .login-form-container {
    padding: 1rem;
    /* Further reduce padding for small screens */
    max-width: 95%;
    /* Set max width to 95% */
    margin-bottom: 100px;
  }

  .login-form-label {
    font-size: 0.85rem;
    /* Further reduce label font size */
  }

  .login-form-input {
    font-size: 0.85rem;
    /* Further reduce input font size */
    padding: 0.5rem;
    /* Reduce padding for inputs */
  }

  .login-form-button {
    font-size: 0.85rem;
    /* Further reduce button font size */
    padding: 0.5rem;
    /* Reduce button padding */
    max-width: 100%;
  }

  .forgot-password-link {
    font-size: 15px;
    /* Further adjust font size for the link */
    text-align: left;
    /* Align to the left */
    margin-right: 19px;
  }

  .logo {
    width: 150px;
    /* Adjust logo size for small screens */
  }
}