/* Outstanding.css */
*{
  color: darkslategray;
}
.outstanding-form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items to the start (left) */
  border: 2px solid #ccc; /* Light border */
  border-radius: 8px; /* Rounded corners */
  padding: 30px; /* Increased padding inside the container */
  width: 100%; /* Full width of its parent */
  max-width: 1800px; /* Increased maximum width */
  margin: 0px 450px 0px 0px; /* Margin around the container */
  box-sizing: border-box; /* Ensure padding and border are included in total width/height */
}

.outstanding-form h1 {
  margin-bottom: 20px;
  color: darkslategray;
}

.outstanding-form div {
  margin-bottom: 15px;
  width: 100%; /* Ensure divs take full width of container */
}

.outstanding-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.outstanding-form .radio-group {
  display: flex; /* Align items horizontally */
  align-items: center; /* Center align items vertically */
  gap: 20px; /* Space between radio buttons */
}

.outstanding-form .radio-group label {
  display: flex; /* Align label and radio button horizontally */
  align-items: center; /* Center align items vertically */
  margin: 0; /* Remove default margin */
}

.outstanding-form input[type="radio"],
.outstanding-form input[type="number"],
.outstanding-form select,
.outstanding-form input[type="date"],
.outstanding-form input[type="text"] {
  width: 100%; /* Full width of parent div */
  padding: 8px; /* Adjust padding for thinner appearance */
  border-radius: 4px; /* Rounded corners for inputs */
  border: 1px solid #ccc; /* Border for inputs */
  box-sizing: border-box; /* Ensure padding and border are included in total width/height */
}

.outstanding-form input[type="radio"],
.outstanding-form input[type="checkbox"] {
  width: auto; /* Width auto for radio and checkbox */
}

.outstanding-form button {
  padding: 6px 12px; /* Smaller padding for a smaller button */
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 4px; /* Rounded corners for button */
  cursor: pointer;
  font-size: 14px; /* Smaller font size for a smaller button */
}

.outstanding-form button:hover {
  background-color: #0056b3; /* Darker shade of blue on hover */
}

/* Your existing CSS here... */

@media (max-width: 768px) {
  .outstanding-form {
    padding: 20px; /* Reduce padding for medium screens */
    margin: 0 20px; /* Reduce side margins */
  }

  .outstanding-form h1 {
    font-size: 1.5em; /* Adjust heading size */
  }

  .outstanding-form button {
    font-size: 12px; /* Adjust button font size */
    padding: 6px 10px; /* Adjust button padding */
  }

  .outstanding-form label {
    font-size: 14px; /* Slightly smaller label font size */
  }

  .outstanding-form input[type="text"],
  .outstanding-form input[type="number"],
  .outstanding-form select,
  .outstanding-form input[type="date"] {
    font-size: 14px; /* Slightly smaller input font size */
  }
}

@media (max-width: 480px) {
  .outstanding-form {
    padding: 15px; /* Further reduce padding for small screens */
    margin: 0 10px; /* Further reduce side margins */
  }

  .outstanding-form h1 {
    font-size: 1.2em; /* Further adjust heading size */
  }

  .outstanding-form button {
    font-size: 10px; /* Further adjust button font size */
    padding: 6px 8px; /* Further adjust button padding */
  }

  .outstanding-form label {
    font-size: 12px; /* Further reduce label font size */
  }

  .outstanding-form input[type="text"],
  .outstanding-form input[type="number"],
  .outstanding-form select,
  .outstanding-form input[type="date"] {
    font-size: 12px; /* Further reduce input font size */
  }
}


.partywise-container {
  position: relative; /* Set position relative to parent for absolute positioning of suggestions */
}

.suggestions-list {
  position: absolute; /* Position suggestions relative to the container */
  top: 100%; /* Place below the input */
  left: 0; /* Align to the left */
  right: 0; /* Stretch to the right */
  background-color: white; /* Background color for suggestions */
  border: 1px solid #ccc; /* Border around the dropdown */
  border-radius: 4px; /* Rounded corners */
  max-height: 200px; /* Limit height */
  overflow-y: auto; /* Scroll if too many items */
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  z-index: 1000; /* Ensure it appears above other elements */
}

.suggestions-list li {
  padding: 5px; /* Padding for each suggestion */
  cursor: pointer; /* Pointer on hover */
}

.suggestions-list li:hover {
  background-color: #f0f0f0; /* Background change on hover */
}




.suggestions-list2 {
  list-style: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  border: 1px solid #ccc; /* Add border around suggestions */
  border-radius: 4px; /* Rounded corners */
  max-height: 200px; /* Limit height */
  overflow-y: auto; /* Scroll if too many items */
  background-color: #fff; /* Background color */
  z-index: 1000; /* Ensure it appears above other elements */
}

.suggestions-list2 li {
  padding: 10px; /* Padding inside each suggestion */
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.2s; /* Smooth background color change */
}

.suggestions-list2 li:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}


/* Full-screen overlay for loader */
.loader-overlay {
  position: fixed; /* Make it fixed to cover the entire screen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255, 255, 255); /* Semi-transparent black background */
  display: flex;
  justify-content: center; /* Center the loader horizontally */
  align-items: center; /* Center the loader vertically */
  z-index: 9999; /* Ensure it is on top of everything */
}

/* Center the loader container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hide the form when loading */
.outstanding-form {
  visibility: visible; /* Make sure it is visible initially */
}

.outstanding-form.loading {
  visibility: hidden; /* Hide the form when loading */
}






