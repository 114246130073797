/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: darkslategray;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f6f9;
  color: #333;
  line-height: 1.6;
  padding: 0;
  margin: 0;
}


.table-container {
  max-width: 1200px;
  width: 100%;
  margin: 30px auto;
  background-color: #ffffff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.company-detail {
  background-color: #ecf0f1;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
  text-align: center;
}

.company-detail h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}

.company-detail p {
  font-size: 16px;
  color: #7f8c8d;
}

.no-data {
  text-align: center;
  color: #e74c3c;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  color: #34495e;
}

th,
td {
  padding: 14px;
  text-align: left;
  font-size: 16px;
  border-bottom: 1px solid #8d8b8b;
}

th {
  background-color: #bbb9b95b;
  font-weight: 600;
  text-align: center;
}

td {
  background-color: #ffffff;
  font-weight: 400;
}

button {
  font-size: 16px;
  color: #2980b9;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

button:hover {
  color: #1a5276;
}

/* Professional Styling for Small Screens */
@media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }

  .table-container {
    padding: 20px;
  }

  .company-detail h2 {
    font-size: 20px;
  }

  .table {
    font-size: 14px;
  }

  th,
  td {
    padding: 12px;
  }

  td button {
    font-size: 14px;
  }

  .no-data {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 20px;
  }

  .company-detail h2 {
    font-size: 18px;
  }

  .table {
    font-size: 12px;
  }

  th,
  td {
    padding: 10px;
  }

  td button {
    font-size: 12px;
  }

  .no-data {
    font-size: 12px;
  }
}
