/* Main container */
.year-select-container {
    max-width: 100%; /* Max width adjusted for mobile */
    margin: 0 auto; /* Center the container */
    padding: 20px; /* Add some padding for spacing */
    background-color: #f9f9f9; /* Light background to mimic mobile app feel */
    border-radius: 12px; /* Rounded corners for mobile-like container */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for elevated effect */
}

/* Form layout */
.year-select-form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Increased gap for better spacing */
}

/* Label styling */
.year-select-label {
    font-size: 15px; /* Larger font size for better readability */
    font-weight: 600; /* Bold for better emphasis */
    color: #333; /* Darker text for contrast */
    margin-bottom: 5px; /* Spacing between label and select */
}

/* Dropdown styling */
.year-select {
    padding: 12px 15px; /* Increase padding for touch targets */
    font-size: 18px; /* Adjust font size for mobile legibility */
    border: 1px solid #ccc; /* Slightly darker border */
    border-radius: 8px; /* Rounded corners for a mobile app feel */
    box-sizing: border-box; /* Include padding in width calculation */
    width: 100%; /* Ensure select takes full width */
    background-color: #ffffff; /* White background */
    color: #333; /* Dark text for readability */
    transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition for hover */
    cursor: pointer; /* Ensure cursor is pointer on hover */
}

/* Hover effect for dropdown */
.year-select:hover {
    border-color: #4c53af !important; /* Green border on hover */
    background-color: #f1f1f1 !important; /* Slightly darker background on hover */
}

/* Focus state */
.year-select:focus {
    outline: none; /* Remove the default focus outline */
    border-color: #4c63af; /* Green border on focus */
    background-color: #e9ffe5; /* Very light green background on focus */
}

/* Submit button styling */
.submit-button {
    padding: 12px 20px; /* Adjust button padding for a bigger touch area */
    font-size: 18px; /* Larger font size for easy tapping */
    cursor: pointer; /* Pointer cursor for clickable button */
    background-color: #007bff; /* Blue color for submit button */
    color: white; /* White text */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    transition: background 0.3s ease; /* Smooth hover effect */
    text-align: center; /* Center the text in the button */
}

/* Hover effect for submit button */
.submit-button:hover {
    background-color: #0056b3; /* Darker shade of blue on hover */
    color: white; /* White text on hover */
}

/* Disabled state for the submit button */
.submit-button:disabled {
    cursor: not-allowed; /* Not-allowed cursor */
    opacity: 0.5; /* Make button look inactive */
}

/* Message styling */
.message {
    font-size: 20px; /* Slightly larger font for readability */
    font-weight: 500; /* Normal weight for message */
    text-align: center; /* Center the message */
    color: #444; /* Darker text for better contrast */
    margin-top: 20px; /* Add some space above message */
}

/* Option elements styling (to customize hover state) */
.year-select option {
    font-size: 15px; /* Larger font size for better readability */
    background-color: #ffffff; /* Default background for options */
    color: #333; /* Dark text color */
}

/* Hover effect for option elements */
.year-select option:hover {
    background-color: #ddd; /* Darker background on hover */
    color: #333; /* Maintain dark text for contrast */
}

/* Responsive styles for smaller screens (e.g., mobile) */
@media (max-width: 768px) {
    /* Container adjustments for smaller screens */
    .year-select-container {
        max-width: 100%; /* Take full width on mobile */
        padding: 15px; /* Add some padding */
        border-radius: 12px; /* Rounded corners for a mobile feel */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for mobile app look */
    }

    /* Label adjustments */
    .year-select-label {
        font-size: 16px; /* Slightly smaller font size */
    }

    /* Year select adjustments */
    .year-select {
        font-size: 16px; /* Adjust font size */
        padding: 14px; /* Increase padding for easier interaction */
        border-radius: 10px; /* More rounded corners for a mobile app look */
    }

    /* Button adjustments */
    .submit-button {
        font-size: 18px; /* Increase button font size */
        padding: 14px; /* Larger padding for touch targets */
    }

    /* Message adjustments */
    .message {
        font-size: 22px; /* Larger font for better legibility on mobile */
        margin-top: 10px; /* Space between form and message */
        margin-left: 0;
    }

    .year-select option{
        font-size: 10px; /* Smaller font size for better readability */
        width: fit-content;
    }
}

/* Full-screen overlay for loader */
.loader-overlay {
    position: fixed; /* Make it fixed to cover the entire screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(255, 255, 255); /* Semi-transparent black background */
    display: flex;
    justify-content: center; /* Center the loader horizontally */
    align-items: center; /* Center the loader vertically */
    z-index: 9999; /* Ensure it is on top of everything */
  }
  
  /* Center the loader container */
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Hide the form when loading */
  .outstanding-form {
    visibility: visible; /* Make sure it is visible initially */
  }
  
  .outstanding-form.loading {
    visibility: hidden; /* Hide the form when loading */
  }
  
  