* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.data-table-container {
  width: 100vw;
  height: 750px; /* Adjust as needed */
  overflow: auto;
  background-color: #f9f9f9; /* Light background for the container */
  padding: 20px;
}

/* Heading styling */
.table-heading {
  font-size: 18px; /* Increased size for better visibility */
  margin-left: 30px;
  color: darkslategray;
}

/* Styling for the total amount */
.total-amount {
  font-weight: bold;
  color: #db3636;
  margin-left: 10px;
}

.bill-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em; /* Adjust as needed */
  margin-right: 5px; /* Space between button and bill number */
}

.details-box {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-top: 5px;
  background-color: #f9f9f9; /* Light background for visibility */
}

.details-item {
  margin-bottom: 5px; /* Space between items */
  font-size: 14px; /* Adjust font size as needed */
}

.details-item strong {
  color: #333; /* Color for labels */
}


.amountHeading{
  font-size: 9px; 
  margin-left: 30px !important;
}


/* Table styling */
.data-table {
  border-collapse: collapse;
  width: 100%;
  position: relative; /* Ensures that the sticky positioning works */
}

/* Table header styling */
.data-table th {
  background-color: #000000; /* Dark background for header */
  color: white; /* White text for better contrast */
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* Sticks the header to the top */
  z-index: 1; /* Ensures the header is above the table body */
  padding: 8px; /* Padding for header */
  border: 1px solid #ddd; /* Border for header */
}

/* Table cell styling */
.data-table th,
.data-table td {
  padding: 8px; /* Consistent padding */
  font-size: 12px; /* Slightly reduced font size */
  text-align: left;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis for overflowing text */
  border: 1px solid #ddd; /* Consistent border */
}

/* Set fixed widths for columns */
.data-table th:nth-child(1),
.data-table td:nth-child(1),
.data-table td:nth-child(2),
.data-table td:nth-child(2),
.data-table td:nth-child(3),
.data-table td:nth-child(3),
.data-table td:nth-child(4),
.data-table td:nth-child(4) {
  width: 250px; /* Adjust as needed */
}

.pdfContainer {
  display: flex;
  justify-content: flex-end; /* Aligns items to the right */
  width: 100%;
}

/* Style for the Generate File button */
.generate-button {
  margin-top: 10px;
  position: relative;
  padding: 8px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.generate-button:hover {
  background-color: #45a049;
}

/* Dropdown content - initially hidden */
.dropdown-content {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  padding: 5px 0;
}

/* Style for individual dropdown buttons */
.dropdown-content button {
  width: 100%;
  padding: 10px;
  background-color: white;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.dropdown-content button:hover {
  background-color: #ddd;
}

/* Prevent dropdown from closing if it's clicked inside */
.dropdown-content button:focus {
  outline: none;
}




/* Alternating row colors */
.data-table tbody tr:nth-child(even) {
  background-color: #f2f2f2; /* Light gray for even rows */
}

.data-table tbody tr:nth-child(odd) {
  background-color: #ffffff; /* White for odd rows */
}

/* Row hover effect */
.data-table tbody tr:hover {
  background-color: #ddd; /* Light gray for row hover effect */
}

/* Responsive styles */
@media (max-width: 768px) {
  .data-table-container {
    height: 215vw; /* Allow container height to adapt to content */
  }

  .data-table {
    min-width: 400px; /* Reduce minimum width for smaller screens */
  }

  .data-table th,
  .data-table td {
    font-size: 12px; /* Adjust font size for better fit on smaller screens */
  }

  .data-table td {
    min-width: 80px; /* Reduce minimum width for smaller screens */
  }
}

/* Back button styling */
.back-button {
  font-size: 14px; /* Smaller font size */
  cursor: pointer;
  background-color: #007bff; /* Primary color for the button */
  color: white;
  border: none;
  border-radius: 4px;
  width: auto; /* Adjusts the button width based on content */
  display: inline-flex; /* Keeps button and text in line */
  align-items: center; /* Centers text vertically */
  text-align: center; /* Centers text horizontally */
}

/* Container for heading and search box */
.heading-search-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between heading and search box */
  flex-grow: 1; /* Allow this to take available space */
  margin-left: 50px; /* Space between button and heading */
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  background-color: #007bff; /* Primary color */
  border: 1px solid #007bff; /* Border matching the background color */
  color: white;
  padding: 0; /* Remove padding */
  margin: 0 2px; /* Small margin */
  cursor: pointer;
  border-radius: 4px; /* Make it square */
  font-size: 16px; /* Increase font size for arrows */
  width: 30px; /* Set width for square shape */
  height: 30px; /* Set height for square shape */
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-button:disabled {
  background-color: #ccc;
  border-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background-color: #0056b3; /* Slightly darker shade */
}

/* Use Unicode arrows for previous and next */
.pagination-button.previous::before {
  content: '\2190'; /* Left arrow */
}

.pagination-button.next::before {
  content: '\2192'; /* Right arrow */
}

.negative-amount {
  background-color: rgb(252, 252, 252); /* You can adjust this color as needed */
  color: rgb(253, 0, 0); /* Optional: change text color for better contrast */
}

.data-table td:nth-child(3),
.data-table td:nth-child(4),
.data-table th:nth-child(5),
.data-table th:nth-child(6),
.data-table th:nth-child(7),
.data-table th:nth-child(8),
.data-table td:nth-child(5),
.data-table td:nth-child(6),
.data-table td:nth-child(7),
.data-table td:nth-child(8),
.data-table th:nth-child(3),
.data-table th:nth-child(4) {
  text-align: right; /* Aligns header and cell content to the right */
  padding-left: 15px; /* Adds some left padding for better spacing */
}

.search-icon {
  cursor: pointer;
  margin-left: 8px; /* Spacing between text and icon */
}

th {
  position: relative;
}

.refresh-button {
  padding: 5px 10px; /* Smaller padding for a compact look */
  font-size: 14px; /* Adjust font size */
  margin-left: 10px; /* Space between the message and button */
  cursor: pointer; /* Pointer on hover */
  border: 1px solid #007bff; /* Border color */
  background-color: #007bff; /* Button background color */
  color: white; /* Text color */
  border-radius: 4px; /* Rounded corners */
  width: auto; /* Make width auto to fit content */
  min-width: 80px; /* Optional: set a minimum width */
}

.refresh-button:hover {
  background-color: #0056b3; /* Darker color on hover */
}

.no-data-container {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Center the content horizontally */
}

.no-data {
  text-align: center; /* Center text in the table cell */
  padding: 10px; /* Add some padding for spacing */
}



.criteria-display span {
  font-size: 16px; /* Font size */
  color: #343a40; /* Dark text color */
  margin-right: 10px; /* Space between date and value */
}

.criteria-display span {
  font-weight: bold; /* Bold for the date */
}


/* Responsive styles */
@media (max-width: 768px) {
  .data-table-container {
    height: 215vw; /* Allow container height to adapt to content */
    padding: 10px; /* Reduce padding */
  }

  .data-table {
    min-width: 300px; /* Reduce minimum width for smaller screens */
  }

  .data-table th,
  .data-table td {
    font-size: 10px; /* Further reduce font size for very small screens */
    padding: 6px; /* Reduce padding for better fit */
  }

  .data-table td {
    min-width: 60px; /* Further reduce minimum width for smaller screens */
  }

  .table-heading {
    font-size: 20px;
    margin-top: 140px;
  }

  .amountHeading{
    margin-top: 5px;
    font-size: 10px;
  }
 

  .search-container {
    width: 100%; /* Make search container full width */
    margin-bottom: 15px; /* Adjust bottom margin */
  }

  .search-box {
    width: 100%; /* Full width for mobile */
  }

  .back-button,
  .refresh-button {
    width: 15%; /* Make buttons full width */
    margin-bottom: 10px; /* Add space below buttons */
  }

  .pagination-button {
    width: 10%; /* Full width for pagination buttons */
    margin: 5px 0; /* Add vertical margin */
  }

  .criteria-display {
    flex-direction: column; /* Stack criteria display items */
    align-items: flex-start; /* Align to start */
    margin: 10px 0; /* Spacing around the component */
  }

  .criteria-display span {
    margin-right: 0; /* Remove right margin */
    margin-bottom: 5px; /* Add bottom margin for spacing */
  }
}

/* Additional mobile-specific adjustments */
@media (max-width: 480px) {
  .data-table th,
  .data-table td {
    font-size: 10px; /* Further reduce font size for very small screens */
  }

  .search-header {
    width: 100%; /* Full width on very small screens */
    font-size: 10px; /* Reduce font size */
  }

  .total-amount {
    font-size: 18px; /* Slightly increase size for better visibility on small screens */
  }


  .pagination-button {
    width: 10%; /* Full width for pagination buttons */
    margin: 5px 0; /* Add vertical margin */
  }

  .back-button,
  .refresh-button {
    width: 15% !important; /* Make buttons full width */
    margin-bottom: 10px !important; /* Add space below buttons */
  }

.data-table td:nth-child(3),
.data-table td:nth-child(4),
.data-table th:nth-child(5),
.data-table th:nth-child(6),
.data-table th:nth-child(7),
.data-table th:nth-child(8),
.data-table td:nth-child(5),
.data-table td:nth-child(6),
.data-table td:nth-child(7),
.data-table td:nth-child(8),
.data-table th:nth-child(3),
.data-table th:nth-child(4) {
  text-align: right; /* Aligns header and cell content to the right */
  padding-left: 0px; /* Adds some left padding for better spacing */
}


}

.party-details {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; /* Spacing between party details */
}



.party-info {
  margin-left: 20px; /* Indentation for details under party names */
 
  border: 1px solid #ccc; /* Light border around info */
  border-radius: 5px; /* Rounded corners for better aesthetics */
}

/* Mobile adjustments for party details */
@media (max-width: 768px) {
  .party-info {
    margin-left: 0; /* Remove indentation on smaller screens */
    border: none; /* No border for a cleaner look */
  }

  .party-names {
    flex-direction: column; /* Stack names vertically on small screens */
    height: 100vh;
    overflow-y: scroll;
  }

  .back-button,
  .refresh-button {
    width: 15% !important; /* Make buttons full width */
    margin-bottom: 10px !important; /* Add space below buttons */
  }

  
}

.party-name {
  font-size: 15px; /* Smaller party name */
  margin-bottom: 5px; /* Space between party names */
  text-align: left;
}


.party-info {
  margin-bottom: 10px;
  background-color:  #f8f4f4;
}

.party-summary {
  display: flex; /* Use Flexbox to align items */
  align-items: center; /* Vertically center the items */
  cursor: pointer; /* Change cursor to pointer for better UX */
  margin-right: 20px;
  padding: 10px;
}

.toggle-sign {
  font-size: 20px; /* Adjust size for visibility */
  margin-right: 10px; /* Space between toggle sign and text */
}

.party-name {
  font-weight: bold; /* Make party name bold */
  margin-right: auto; /* Push total amount to the right */
}

.total-amt {
  font-weight: bold; /* Make total amount bold */
}

.data-table {
  width: 100%; /* Full width for the data table */
  border-collapse: collapse; /* Collapse borders for a cleaner look */
}

.data-table th, .data-table td {
  border: 1px solid #e9e3e3d7; /* Border for table cells */
  padding: 8px; /* Padding for table cells */
  text-align: left; /* Align text to the left */
}

.no-data {
  text-align: center; /* Center text for no data available */
  color: #888; /* Lighter color for no data message */
}

.loading {
  text-align: center; /* Center loading message */
}

.error {
  text-align: center; /* Center error message */
  color: red; /* Red color for error */
}

@media (max-width: 768px) {
  .data-table-container {
    padding: 10px;
  }

  .data-table {
    min-width: 300px;
  }

  .data-table th,
  .data-table td {
    font-size: 10px;
    padding: 6px;
  }


  .party-details {
    flex-direction: column;
  }

  .party-info {
    margin-left: 0;
    padding: 5px;
    background-color: #f1efefd7;
    margin-bottom: 10px;
    border-color: #3b3838;
  }

  .party-summary {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
  }

  .party-name {
    font-weight: bold;
    flex-grow: 1;
  }

  .header-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Additional styling for search box to align well */
  .search-header {
    padding: 8px;
    font-size: 16px;
    border: 2px solid #007bff; /* Border color */
    border-radius: 4px;
    width: 250px;
    align-items: left;
  }
}

.no-data {
  text-align: center;
  color: #888;
}

.loading,
.error {
  text-align: center;
}

.error {
  color: red;
}


.search-header {
  padding: 8px;
  font-size: 16px;
  border: 2px solid #007bff;
  border-radius: 4px;
  margin-left: 78%; /* Adjust spacing as necessary */
  width: 300px;
}

@media (max-width: 768px) {
  .search-header {
    margin-left: 0; /* Remove left margin on smaller screens */
    width: 80%; /* Full width for mobile */
    max-width: none; /* Remove max width restriction */
    margin-top: 15px;
  }

  .party-summary-header {
    margin-left: 0px !important; 
}
}

.sortable-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: #2f27a5;
}

.party-summary-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 10px;
  background-color: #c8e4f67d;
  padding: 5px;
  border-radius: 8px;
  margin-left: 15px;
}


@media (max-width: 480px) {
  /* Adjust the data table for smaller screens */
  .data-table-container {
    padding: 10px; /* Reduce padding */
  }

  .data-table {
    min-width: 300px; /* Adjust minimum width */
  }

  .data-table th,
  .data-table td {
    font-size: 10px; /* Further reduce font size for very small screens */
    padding: 6px; /* Reduce padding for better fit */
  }

  .table-heading {
    font-size: 20px; /* Smaller heading for better fit */
    margin-top: 140px !important; /* Add margin bottom */
  }

  .search-header {
    width: 80%; /* Full width on very small screens */
    margin-top: 15px; /* Add top margin */
  }

  .pagination-button {
    width: 6%; /* Adjust width for pagination buttons */
  }

  .party-info {
    margin-left: 0; /* Remove indentation on smaller screens */
    padding: 5px; /* Add padding */
  }

  .party-names {
    flex-direction: column; /* Stack names vertically on small screens */
    height: auto; /* Allow auto height */
    overflow-y: auto; /* Enable scrolling if necessary */
  }

}



