
  
  .forgot-password-container h2 {
    text-align: center;
    color: #333;
  }
  
  .forgot-password-container form {
    display: flex;
    flex-direction: column;
  }
  
  .forgot-password-container label {
    font-size: 16px;
    margin-bottom: 8px;
    color: #333;
  }
  
  .forgot-password-container input[type="email"] {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .forgot-password-container button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .forgot-password-container button:hover {
    background-color: #0056b3;
  }
  
  .error {
    color: #d9534f;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .success {
    color: #5bc0de;
    font-size: 14px;
    margin-top: 10px;
  }
  

/* Your existing CSS here... */

@media (max-width: 480px) {
  .forgot-password-container {
    padding: 15px; /* Reduce padding for smaller screens */
    width: 90%; /* Set width to 90% for better fit */
    margin: 0 auto; /* Center the container */
  }

  .forgot-password-container h2 {
    font-size: 20px; /* Adjust heading size */
  }

  .forgot-password-container label {
    font-size: 14px; /* Slightly smaller font size for labels */
  }

  .forgot-password-container input[type="email"] {
    font-size: 14px; /* Adjust input font size */
    padding: 8px; /* Reduce padding for inputs */
  }

  .forgot-password-container button {
    font-size: 14px; /* Adjust button font size */
    padding: 8px; /* Reduce button padding */
  }

  .error, .success {
    font-size: 12px; /* Adjust font size for messages */
  }
}

/* Your existing CSS here... */

@media (max-width: 768px) {
  .forgot-password-container {
    padding: 20px; /* Adjust padding for medium screens */
    width: 85%; /* Set width to 85% for better fit */
    margin: 0 auto; /* Center the container */
  }

  .forgot-password-container h2 {
    font-size: 22px; /* Slightly smaller heading for medium screens */
  }

  .forgot-password-container label {
    font-size: 15px; /* Adjust label font size */
  }

  .forgot-password-container input[type="email"] {
    font-size: 15px; /* Adjust input font size */
    padding: 9px; /* Reduce padding for inputs */
  }

  .forgot-password-container button {
    font-size: 15px; /* Adjust button font size */
    padding: 9px; /* Reduce button padding */
  }

  .error, .success {
    font-size: 13px; /* Adjust font size for messages */
  }
}

@media (max-width: 480px) {
  .forgot-password-container {
    padding: 15px; /* Further reduce padding for smaller screens */
    width: 90%; /* Set width to 90% for better fit */
  }

  .forgot-password-container h2 {
    font-size: 20px; /* Adjust heading size for small screens */
  }

  .forgot-password-container label {
    font-size: 14px; /* Slightly smaller font size for labels */
  }

  .forgot-password-container input[type="email"] {
    font-size: 14px; /* Adjust input font size */
    padding: 8px; /* Reduce padding for inputs */
  }

  .forgot-password-container button {
    font-size: 14px; /* Adjust button font size */
    padding: 8px; /* Reduce button padding */
  }

  .error, .success {
    font-size: 12px; /* Adjust font size for messages */
  }
}
