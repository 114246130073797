/* File: SystemAdminsTable.css */

.table-container-admin {
  border-radius: 8px;
  margin-top: 0; /* Removed the percentage value and set margin-top to 0 */
  padding: 20px; /* Add some padding to the container for aesthetic spacing */
  margin-left: 200px;
  max-height: 100vh; /* Limit the height of the table container */
  overflow-y: auto; /* Allow vertical scrolling if the content overflows */
}

h2 {
  text-align: center;
  color: #333;
  font-size: 18px; /* Make the heading smaller */
}

.admins-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0; /* Ensure the table starts right after the container */
}

.admins-table th,
.admins-table td {
  padding: 5px; /* Smaller padding for a more compact look */
  text-align: center;
  border: 1px solid #ddd;
  font-size: 12px; /* Smaller font size */
}

.admins-table th {
  background-color: #f2f2f2;
}

.edit-btn {
  background-color: #4064a1;
  color: white;
  border: none;
  padding: 6px 12px; /* Smaller padding */
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px; /* Slightly smaller font size for the button */
}

.edit-btn:hover {
  background-color: #45a049;
}

/* Media Queries for responsiveness */

/* For Tablets and Smaller Devices */
@media (max-width: 768px) {
  .admins-table th,
  .admins-table td {
    font-size: 11px; /* Slightly smaller font for better mobile fit */
    padding: 6px; /* Smaller padding for mobile */
  }

  .admins-table {
    font-size: 13px; /* Make table font smaller on smaller screens */
  }

  .edit-btn {
    padding: 5px 10px; /* Reduce padding for smaller screens */
  }
}

/* For Mobile Devices */
@media (max-width: 480px) {
  .admins-table th,
  .admins-table td {
    font-size: 9px; /* Smaller font size for mobile devices */
    padding: 5px; /* Smaller padding for compact view */
  }

  .edit-btn {
    font-size: 9px;
    padding: 3px 6px;
  }
}

/* Styling for the additional connections table */
.additional-container-table {
  margin-top: 40px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.additional-header {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.additional-table {
  width: 100%;
  border-collapse: collapse;
}

.additional-table th, .additional-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.additional-header-row {
  background-color: #f4f7fb;
  color: #333;
}

.additional-column {
  font-weight: bold;
}

.additional-row:hover {
  background-color: #f1f1f1;
}

.additional-data {
  font-size: 14px;
  color: #333;
}

.additional-no-data {
  text-align: center;
  color: #666;
}

.additional-no-data-message {
  font-style: italic;
  padding: 20px;
  color: #aaa;
}

/* Search box styling (right-aligned and smaller) */
.search-boxes {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  width: 100%;
}

.search-boxes input {
  padding: 6px 12px;
  width: 180px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

/* Pagination container styling (smaller and centered) */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

/* Pagination button styling (smaller size) */
.pagination button {
  padding: 6px 12px;
  font-size: 14px;
  margin: 0 5px;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
}

.pagination span {
  font-size: 14px;
}
