/* Basic sidebar styles */
.sidebar {
  width: 250px; /* Width of the sidebar when expanded */
  height: 100vh; /* Full height */
  background-color: rgb(255, 255, 255); /* White background */
  position: fixed; /* Fixed position */
  top: 0;
  left: 0;
  transition: width 0.3s ease, background-color 0.3s ease; /* Smooth transition for collapsing */
  color: #131313; /* Text color for better contrast */
  z-index: 1000; /* Ensure sidebar is above other elements */

}

.sidebar.collapsed {
  width: 0; /* Width when collapsed */
}

/* Header styles */
.sidebar header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.sidebar header .image img {
  width: 150%; /* Logo responsive size */
  max-width: 150px; /* Limit logo size */
  height: 70px;
}

.sidebar .toggle img {
  width: 25px; /* Smaller size for toggle icon */
  height: 25px; /* Maintain aspect ratio */
  cursor: pointer; /* Pointer cursor for the toggle */
}

/* Menu styles */
.menu-bar {
  margin-top: 20px; /* Space above the menu */
}

.menu-links {
  list-style: none; /* Remove default list styles */
  padding: 0;
}

.nav-link {
  padding: 15px 10px; /* Space around menu items */
  cursor: pointer; /* Pointer cursor for nav items */
  transition: background 0.3s ease; /* Smooth background transition */
  color: black;
  display: block;
}

.nav-link:hover {
  background-color: #bdaeae; /* Light grey on hover */
}

.text {
  display: inline-block; /* Align text with icons */
  color: black;
}

/* Hide text when collapsed */
.hidden {
  display: none; /* Hide element */
}

/* Bottom content styles */
.bottom-content {
  margin-top: auto; /* Push logout button to bottom */
  padding: 20px;
}

.logout-button {
  background-color: #3498db; /* Blue button color */
  border: none;
  color: white; /* Button text color */
  padding: 10px 15px; /* Padding */
  cursor: pointer; /* Pointer cursor */
  border-radius: 5px; /* Rounded corners */
  width: 100%; /* Full width */
  transition: background 0.4s ease; /* Smooth background transition */
}

.logout-button:hover {
  background-color: #0056b3; /* Darker blue shade on hover */
  color: white; /* Button text color */
}

/* Collapsed state styles */
.sidebar.collapsed .menu-links,
.sidebar.collapsed .bottom-content {
  display: none; /* Hide menu and buttons when collapsed */
}

/* Remove dots in front of the logout button */
.bottom-content ul {
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  list-style: none; /* No list styles */
}

/* Responsive styles */
@media (max-width: 768px) {

  .sidebar {
    width: 0; /* Collapse sidebar on small screens */
  }

  .sidebar.collapsed {
    width: 0; /* Ensure it's still collapsed */
  }

  .sidebar.collapsed {
    background-color: rgb(255, 255, 255); /* Maintain background when collapsed */
  }

  .sidebar:not(.collapsed) {
    width: 250px; /* Expand width when opened */
  }

  .nav-link {
    padding: 10px 15px; /* Reduced padding for smaller screens */
    font-size: 14px; /* Smaller font size */
  }

  .bottom-content {
    padding: 10px; /* Reduced padding */
  }

  .logout-button {
    padding: 8px; /* Smaller button padding */
    font-size: 14px; /* Adjust button font size for smaller screens */
  }

  .sidebar header {
    flex-direction: row; /* Keep header elements in a row */
    justify-content: space-between; /* Space between items */
    padding: 10px; /* Reduced padding for header */
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 0; /* Ensure collapse on very small screens */
  }

  .nav-link {
    font-size: 20px; /* Smaller font size for very small screens */
    padding: 20px 20px;
  }

  .logout-button {
    font-size: 12px; /* Smaller button font size */
  }
}

