/* StockReport.css */
*{
   margin-right: 0%;
   margin-left: 0%;
   color: darkslategray;
}
/* Main stock-report container */
.stock-report {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content */
    background-color: #ffffff; /* Light background for the form container */
    padding: 30px; /* Increased padding inside the container */
    border-radius: 8px; /* Rounded corners */
    max-width: 800px; /* Max width for desktop */
    margin: 20px auto; /* Center the container */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.stock-report h1 {
    margin-bottom: 20px;
    color: darkslategray;
}

/* Stock report form */
.stock-report-form {
    width: 100%; /* Full width of the form */
}


/* Label for form inputs */
.stock-report-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

/* Style for text, number, select, and date inputs */
.stock-report-form input[type="text"],
.stock-report-form input[type="number"],
.stock-report-form select,
.stock-report-form input[type="date"] {
    width: 100%; /* Full width of parent div */
    padding: 10px; /* Padding for inputs */
    border-radius: 4px; /* Rounded corners for inputs */
    border: 1px solid #929191; /* Light border for inputs */
    box-sizing: border-box; /* Include padding and border in width */
    transition: border-color 0.3s;
}

.stock-report-form input[type="text"]:focus,
.stock-report-form input[type="number"]:focus,
.stock-report-form select:focus,
.stock-report-form input[type="date"]:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none; /* Remove outline */
}

/* Submit button */
.stock-report-form button {
    padding: 10px 15px; /* Padding for button */
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    border-radius: 4px; /* Rounded corners for button */
    cursor: pointer;
    margin-top: 10px;
    font-size: 16px; /* Button font size */
    transition: background-color 0.3s; /* Smooth transition */
}

.stock-report-form button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

/* Mobile Styles */
@media (max-width: 768px) {
    .stock-report {
        padding: 20px; /* Reduce padding for medium screens */
        margin: 10px; /* Reduce side margins */
    }

    .stock-report h1 {
        font-size: 1.5em; /* Adjust heading size */
    }

    .stock-report-form button {
        font-size: 14px; /* Adjust button font size */
        padding: 8px 10px; /* Adjust button padding */
    }

    .stock-report-form label {
        font-size: 14px; /* Slightly smaller label font size */
    }

    .stock-report-form input[type="text"],
    .stock-report-form input[type="number"],
    .stock-report-form select,
    .stock-report-form input[type="date"] {
        font-size: 14px; /* Slightly smaller input font size */
    }
}

@media (max-width: 480px) {
    .stock-report {
        padding: 15px; /* Further reduce padding for small screens */
    }

    .stock-report h1 {
        font-size: 1.2em; /* Further adjust heading size */
    }

    .stock-report-form button {
        font-size: 12px; /* Further adjust button font size */
        padding: 6px 8px; /* Further adjust button padding */
    }

    .stock-report-form label {
        font-size: 12px; /* Further reduce label font size */
    }

    .stock-report-form input[type="text"],
    .stock-report-form input[type="number"],
    .stock-report-form select,
    .stock-report-form input[type="date"] {
        font-size: 12px; /* Further reduce input font size */
    }
}

.suggestion-list {
    max-height: 200px;  /* Set max height based on your UI */
    overflow-y: auto;   /* Allow vertical scrolling */
    border: 1px solid #ccc;
    margin-top: 5px;
    padding: 0;
    list-style-type: none;
    position: absolute;  /* Ensure suggestions are positioned above other elements */
    background-color: white;
  }
  
  .suggestion-list li {
    padding: 5px;
    cursor: pointer;
  }
  
  .suggestion-list li.highlighted {
    background-color: #d3d3d3;  /* Highlighted item background */
  }
  
  

  

