/* Ensure body scroll works properly */
body {
  overflow-x: hidden; /* Disable horizontal overflow */
  overflow-y: auto; /* Enable vertical scrolling */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Align the body content vertically */
}

/* Dashboard Container */
.dashboard-container {
  height: auto;  /* Ensure the container takes full height of the viewport */
  margin-left: 260px; /* Adjust as per your sidebar or layout */
  flex-grow: 1; /* Allow it to grow to fill space */
  padding: 10px; /* Optional padding to give some space around the content */
  box-sizing: border-box; /* Ensure padding is included in total height */
  overflow-y: auto; /* Ensure the container scrolls vertically */
  margin-top: 400px;
}

/* Info Container Styles */
.info-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center text */
  margin-bottom: 10px;
  width: 100%; /* Full viewport width */
  padding: 5px;
  margin-top: 50px;
  top: 0; /* Stick to the top */
  background-image: linear-gradient(to bottom, #66b5d449, #669ac53d, #66b5d449); /* Gradient background */
  z-index: 10; /* Ensure it stays above other content */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Enhanced box shadow */
  transition: background-color 0.3s, box-shadow 0.3s; /* Transition effect */
  border-radius: 10px;
  border: 1px solid #ccc;
}

/* Make the info-container non-sticky so it doesn't interfere with scrolling */
.info-container {
  position: relative;
  margin-top: 10px;
  padding: 10px;
}

/* Table container */
.table-container1 {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

.amount-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  font-size: 0.70em;
  background-color: transparent;
}

.amount-table th,
.amount-table td {
  border: 1px solid rgba(163, 159, 159, 0.5);
  padding: 3px 4px;
  text-align: left;
  background-color: transparent;
  white-space: nowrap;
  font-size: 10px;
}

.amount-table th {
  font-weight: bold;
}

.amount-table td {
  color: #333;
}

.amount-table td.red {
  color: red;
}

.amount-table td.green {
  color: green;
}

/* Heading and paragraph styles */
.info-container h1 {
  font-size: 1.5em;
  color: #333333;
  margin-bottom: 10px;
}

.info-container p {
  font-size: 1.2em;
  color: #555555;
}

/* Card Row */
.card-row {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

/* Card */
.card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  background-image: linear-gradient(to bottom, #bcecdc38, #66b5d434);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  width: 100vw;
  max-width: 350px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card:hover {
  transform: scale(1.05);
}

/* Pie chart styles */
.pie-chart {
  max-width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.pie-chart p {
  margin: 4px;
  font-size: 13px;
  text-align: center;
}

.amount-label {
  font-size: 14px;
}

.amount {
  font-weight: bold;
}

.amount-change {
  font-size: 12px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .dashboard-container {
    margin-left: 0;
    margin-top: 0px;
    height: 100vh;
  }


  .card {
    width: 120vw;
    max-width: 400px;
    margin-right: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-row {
    flex-direction: column;
    justify-content: center;
  }


  .cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
  }

  @media (max-width: 430px) {
    .dashboard-container {
      padding: 5px; /* Adjust padding for smaller screens */
    }
  
    .card {
      width: 91vw; /* Full width of the viewport */
      max-width: none; /* Remove max-width restriction */
      padding: 15px; /* Smaller padding */
    }
  
    .card-row {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .info-container {
      padding: 5px; /* Adjust padding for smaller screens */
      margin-top: 10px; /* Reduce the margin on top */
    }
  
    .amount-table {
      font-size: 9px; /* Make table text smaller on smaller screens */
    }
  
    .amount-table th,
    .amount-table td {
      padding: 3px 6px; /* Adjust padding in table cells */
    }
  
    .pie-chart {
      max-width: 150px; /* Smaller pie chart for mobile */
    }
  }
}