/* General Styling for the Sidebar */
.sidebar-container {
  width: 200px;
  height: 100%;
  position: fixed;
  top: 0;
  left: -200px; /* Initially hidden off-screen */
  background-color: #cee0f3;
  color: white;
  font-family: Arial, sans-serif;
  padding-top: 5px;
  transition: all 0.3s ease;
  z-index: 1000; /* Ensure sidebar is above other content */
}

/* When sidebar is open, show it on screen */
.sidebar-container.open {
  left: 0; /* Move the sidebar on-screen */
}

/* Sidebar header styling */
.sidebar-header-section {
  text-align: center;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

/* Styling for the logo */
.sidebar-logo-img {
  width: 80%; /* Adjust this to make the logo smaller */
  height: auto;
  transition: all 0.3s ease;
}

/* Sidebar Toggle Button (on screen) */
.sidebar-toggle-btn {
  position: fixed;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  z-index: 1100; /* Ensure it's above other elements */
}

.sidebar-toggle-btn:focus {
  outline: none;
}

/* Links and Buttons Styling */
.sidebar-nav-links {
  list-style-type: none;
  padding-left: 0;
}

.sidebar-nav-links li {
  padding: 5px 15px;
  text-align: left;
  transition: background-color 0.3s;
}

.sidebar-nav-links li:hover {
  background-color: #8dbde4;
}

.sidebar-nav-link,
.logout-btn-admin {
  color: rgb(22, 21, 21);
  text-decoration: none;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: transparent;
  width: 100%;
  transition: color 0.3s;
}

/* Media Queries for Responsiveness */

/* For Tablets and Smaller Devices */
@media (max-width: 768px) {
  /* Ensure sidebar is hidden by default */
  .sidebar-container {
    left: -200px; /* Hide sidebar off-screen */
  }

  /* Show sidebar when it is expanded */
  .sidebar-container.open {
    left: 0; /* Show sidebar on toggle */
  }

  /* Show toggle button on mobile when sidebar is collapsed */
  .sidebar-toggle-btn {
    display: block;
  }

  /* Adjust link padding for mobile */
  .sidebar-nav-links li {
    padding: 10px 15px;
  }

  .sidebar-nav-link,
  .logout-btn-admin {
    font-size: 16px;
  }

  .sidebar-header-section h2 {
    display: none; /* Hide title on mobile */
  }

  /* Make logo smaller on mobile */
  .sidebar-logo-img {
    width: 60%; /* Logo adjustment for mobile view */
  }
}
