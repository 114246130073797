.sys-admin-form-container-edit {
  background-color: #fff;
  padding: 15px; /* Reduced padding */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 650px; /* Reduced max width */
  margin: 0 auto;
  font-family: Arial, sans-serif;
 
  max-height: 82vh; /* Set max-height to viewport height */
}

/* General Button Styling */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.sys-admin-update-btn,
.sys-admin-delete-btn {
  width: 33%;
  padding: 10px;
  background-color: #4064a1;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.sys-admin-update-btn:hover {
  background-color: #218838;
}

.sys-admin-delete-btn {
  background-color: #d9534f; /* Red background for Delete */
}

.sys-admin-delete-btn:hover {
  background-color: #c9302c; /* Darker red for Delete */
}

.edit-button{
  background-color: #4064a1;
  color: white;
  border: none;
  padding: 6px 12px; /* Smaller padding */
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px; /* Slightly smaller font size for the button */
}

/* Media Queries for Responsiveness */

/* For Tablets and Smaller Devices */
@media (max-width: 768px) {
  .button-container {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
    gap: 10px; /* Add gap between buttons */
  }

  .sys-admin-update-btn,
  .sys-admin-delete-btn {
    width: 100%; /* Full width for buttons */
  }
}

/* For Mobile Devices */
@media (max-width: 480px) {
  .button-container {
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Increase gap between buttons */
  }

  .sys-admin-update-btn,
  .sys-admin-delete-btn {
    width: 100%; /* Full width for buttons on mobile */
    font-size: 12px; /* Smaller font size */
    padding: 12px; /* Adjust padding for better fit */
  }

  .sys-admin-form-container-edit {
        padding: 10px;
        margin-top: 120px;
        margin-left: -20%;
        margin-right: -20%;
  }

  h2 {
    font-size: 16px; /* Smaller heading for mobile */
  }
}



/* Table header */
.additional-header {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

/* Table */
.additional-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

/* Table headers */
.additional-header-row th {
  background-color: #ddd;
  color: rgb(24, 24, 24);
  text-align: center;
  font-size: 10px;
}

/* Table body */
.additionalUser-data{
  font-size: 10px;
  padding: 15px;
  align-items: center;
}

/* Table rows */
.additional-row {
  border-bottom: 1px solid #ddd;
}

.additional-row:hover {
  background-color: #f1f1f1;
}

/* Table cells */
.additional-column {
  padding: 12px 15px;
  text-align: left;
}

.additional-data {
  text-align: left;
}


/* No data message */
.additional-no-data {
  text-align: center;
}

.additional-no-data-message {
  color: #999;
  font-size: 1rem;
  padding: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .additional-table th, .additional-table td {
    padding: 10px 8px;
    font-size: 0.85rem;
  }

  .additional-header {
    font-size: 1.2rem;
  }
}

.container-table-additional{
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 50px;
}