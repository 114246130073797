.change-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 50px;
    padding-right: 65px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    position: relative; /* Position for the back button */
}

.change-password-title {
    text-align: center; /* Center text for title */
}

.back-button {
    position: absolute; /* Absolute positioning */
    top: 10px; /* Positioning */
    left: 10px;
    z-index: 10; /* Ensure it stays above other elements */
    border: none; /* Remove border */
    background-color: #007bff; /* Button color */
    cursor: pointer; /* Cursor pointer */
    transition: background-color 0.3s; /* Transition effect */
    padding: 10px;
}

.back-button:hover {
    background-color: #0056b3; /* Hover effect */
}

.form-group {
    margin-bottom: 15px; /* Margin between form groups */
}

.form-label {
    display: block; /* Block display */
    margin-bottom: 5px; /* Margin below label */
}

.form-input {
    width: 110%; /* Input width */
    padding: 8px; /* Padding */
    border: 1px solid #ccc; /* Border style */
    border-radius: 4px; /* Rounded corners */
    box-sizing: border-box; /* Include padding in width */
}

.submit-button-pwd {
    width: 110%; /* Full width for submit button */
    padding: 10px; /* Padding */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    background-color: #007bff; /* Button background color */
    color: white; /* Text color */
    cursor: pointer; /* Pointer cursor */
    transition: background-color 0.3s; /* Transition effect */
}

.submit-button:hover {
    background-color: #0056b3; /* Hover effect */
}

.error-message  {
    color: red; /* Error message color */
    text-align: center; /* Center align */
    margin-bottom: 10px; /* Margin below */
}

.success-message {
    color: green; /* Success message color */
    text-align: center; /* Center align */
    margin-bottom: 10px; /* Margin below */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .change-password-container {
        padding: 15px; /* Reduced padding */
    }

    .form-input, .submit-button {
        width: 100%; /* Full width on smaller screens */
    }

    .back-button {
       padding: 10PX;
       margin-top: 40px;
    }
}

@media (max-width: 480px) {
    .change-password-title {
        font-size: 1.5em; /* Adjust font size */
    }

    .change-password-container {
        max-width: 95%; /* More width on very small screens */
    }

    .form-group {
        margin-bottom: 10px; /* Reduced margin */
    }

    .back-button {
        padding: 10PX;
        margin-top: 20px;
     }
}
