.header {
    padding: 15px;
    text-align: center;
}

.header-company-name {
    margin: 0;
    font-size: 2rem; /* Base font size */
    font-weight: bold; /* Optional: make it bold */
    color: darkslategray;
}

.header-date-range {
    margin: 5px 0 0;
    font-size: 1.2rem; /* Base font size */
    color: darkslategray;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {

    .header{
        margin-bottom: 0%;
    }

    .header-company-name {
        margin-left: 10px;
        font-size: 1.5rem; /* Smaller font size for tablets */
    }
    
    .header-date-range {
        font-size: 1rem; /* Smaller font size for tablets */
    }

}

/* iPhone 14 Pro Max, 15, 16 specific adjustments */
@media (max-width: 430px) { /* Approximate width for iPhones */
    .header-company-name {
        margin-left: 10px;
        font-size: 1.4rem; /* Adjust font size */
    }
    
    .header-date-range {
        font-size: 0.9rem; /* Adjust font size */
    }
}

/* Standard PC with 1900px resolution */
@media (min-width: 1900px) {
    
    .header-company-name {
        font-size: 2.5rem; /* Increase font size for large screens */
    }
    
    .header-date-range {
        font-size: 1.5rem; /* Increase font size for large screens */
    }
}

@media (max-width: 480px) {
    .header-company-name {
        font-size: 1.2rem; /* Smaller font size for mobile */
    }
    
    .header-date-range {
        font-size: 0.9rem; /* Smaller font size for mobile */
    }
}
