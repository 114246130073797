/* General Styling for the Form Container */
.sys-admin-form-container {
  background-color: #fff;
  padding: 15px; /* Reduced padding */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1000px; /* Reduced max width */
  margin: 0 auto;
  font-family: Arial, sans-serif;
  overflow-y: auto; /* Allow vertical scrolling */
  max-height: 100vh; /* Set max-height to viewport height */
}

/* Form Heading */
h2 {
  text-align: center;
  font-size: 20px; /* Reduced font size */
  color: #3f3f3f;
  margin-bottom: 10px; /* Reduced margin */
}

/* Form Groups */
.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Reduced gap */
  justify-content: space-between;
  margin-bottom: 10px; /* Reduced bottom margin */
}

/* Styling for Each Form Field (input, select, textarea) */
.form-field {
  flex: 1 1 30%;
  min-width: 180px; /* Reduced min-width */
}

.form-field label {
  font-size: 10px; /* Reduced font size */
  color: #403e3e;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-field input,
.form-field select,
.form-field textarea {
  width: 100%;
  padding: 8px; /* Reduced padding */
  border: 1px solid #ddd;
  border-radius: 4px; /* Slightly smaller border radius */
  font-size: 12px; /* Smaller font size */
  box-sizing: border-box;
  display: block;
}

.form-field textarea {
  height: 80px; /* Reduced height */
}

/* DatePicker Custom Styling */
.react-datepicker-wrapper {
  width: 100%;
}

/* Fix for DatePicker Input Borders */
.react-datepicker__input-container {
  display: block;
  position: relative;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 8px; /* Reduced padding */
  border-radius: 4px;
  font-size: 12px; /* Smaller font size */
  box-sizing: border-box;
}

/* Remove focus outline and adjust border color on focus */
.react-datepicker__input-container input:focus {
  outline: none; /* Remove focus outline */
  border-color: #4064a1; /* Set border color on focus */
}

/* Styling for DatePicker Calendar */
.react-datepicker__triangle {
  display: none; /* Optionally hide the triangle */
}

/* Full Width Fields */
.full-width {
  width: 100%;
  padding: 8px; /* Reduced padding */
  box-sizing: border-box;
}

/* Submit Button Styling */
.sys-admin-submit-btn {
  width: 100%;
  padding: 10px; /* Reduced padding */
  background-color: #4064a1;
  color: white;
  font-size: 14px; /* Smaller font size */
  border: none;
  border-radius: 4px; /* Smaller border radius */
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px; /* Reduced top margin */
}

.sys-admin-submit-btn:hover {
  background-color: #218838;
}

/* Media Queries for Responsiveness */

/* For Tablets and Smaller Devices */
@media (max-width: 768px) {
  .sys-admin-form-container {
    padding: 15px;
  }

  .form-group {
    flex-direction: column;
  }

  .form-field {
    flex: 1 1 100%; /* Make all fields full width */
  }

  .sys-admin-submit-btn {
    font-size: 12px; /* Smaller font size */
  }

  h2 {
    font-size: 18px; /* Smaller heading */
  }
}

/* For Mobile Devices */
/* For Mobile Devices */
@media (max-width: 480px) {
  .sys-admin-form-container {
    padding: 15px; /* Increased padding */
    max-width: 200%; /* 200% width on mobile */
    margin-left: -35%; /* Center the form by offsetting it */
    margin-right: -35%;
    margin-top: 120px; /* Added top margin of 20px */
    overflow-x: auto;
    overflow-y: auto; /* Ensure scrolling on mobile if content overflows */
    max-height: 100vh; /* Prevent content from overflowing vertically */
  }

  .form-group {
    flex-direction: column;
  }

  .form-field {
    flex: 1 1 100%;
  }

  .sys-admin-submit-btn {
    font-size: 12px; /* Smaller font size */
  }

  h2 {
    font-size: 16px; /* Smaller heading */
  }
}



