/* Chart container with gradient background and border-radius */

.chart-container {
  width: 100%;
  max-width: 800px; /* Set max-width to prevent the chart from stretching too far on large screens */
  background-image: linear-gradient(to bottom, #bcecdc38, #66b5d434);
  border-radius: 15px; /* Increased border radius for a more rounded look */
  padding: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #ccc;
  overflow-x: auto; /* Enable horizontal scrolling within the chart container */
  display: block; /* Ensure it's block-level for proper layout */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Soft shadow for a subtle pop effect */
}



/* Style for the dropdown select */
.chart-dropdown {
  padding: 5px;
  border-radius: 8px;
  font-size: 12px;
  width: 90px; /* Adjusted width for better layout */
  cursor: pointer;
  margin-left: 400px; /* Align dropdown to the right */
  transition: background-color 0.3s ease, border-color 0.3s ease;
  background-color: #fff; /* White background for better contrast */
  color: #333; /* Darker text for readability */
}


/* Loader container centered vertically and horizontally */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 285px;
}

/* Container for the chart title and dropdown, with flexbox layout */
.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 10px; /* Added padding for spacing */
}

.chart-header h2 {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin: 0;
  text-align: left;
  font-family: 'Arial', sans-serif;
}

/* Responsive Styles for Mobile & Tablet Devices */

/* For medium screens (tablets) */
@media (max-width: 768px) {
  .chart-container {
    width: 90%; /* Make chart container take up more space on tablets */
    padding: 15px;
  }

  /* Adjust chart height for tablets */
  .chart-container .chart {
    height: 250px; /* Reduced chart height on smaller screens */
  }

  .chart-header h2 {
    font-size: 1.3em; /* Adjust heading font size */
  }

  .chart-dropdown {
    width: 90px; /* Increase width slightly for better usability on tablets */
    margin-left: 0; /* Reset left margin to align better */
    margin-right: 0; /* Remove any right margin */
  }
}

/* For small screens (phones) */
@media (max-width: 480px) {
  .chart-container {
    width: 385px; /* Keep it full width on small devices */
    padding: 10px; /* Reduce padding for mobile */
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    margin-right: 30px;
  }

  .chart-container h2 {
    font-size: 14px; /* Smaller font for the title */
  }

  .chart-dropdown {
    width: 90px; /* Make dropdown a little wider for easy touch interaction */
    font-size: 10px; /* Adjust font size for readability on smaller screens */
    margin-left: 0; /* Center align dropdown on mobile */
    margin-right: 0;
    display: block; /* Ensure dropdown is block-level element */
    margin-top: 10px; /* Add some space between dropdown and other elements */
  }
}
