/* Apply a clean font family globally */
* {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .stock-table-container{
    width: 100vw;
    height: 750px; /* Adjust as needed */
    overflow: auto;
    background-color: #f9f9f9; /* Light background for the container */
  }
  
  .stock-heading {
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Error message styling */
  .error {
    color: red;
    font-weight: bold;
    margin-top: 20px;
  }
  
  /* Wrapper for the table to allow horizontal scrolling */
  .data-table-wrapper {
    width: 100vw;
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  
  /* Table styling */
  .stock-data-table {
    width: 100%;
    border-collapse: collapse; /* Makes sure borders are collapsed into a single line */
    margin-top: 20px;
    border: 1px solid #ddd;
    background-color: #fff;
    min-width: 800px; /* Add minimum width to ensure horizontal scrolling */
  }
  
  /* Table header styling */
  .stock-data-table-header {
    background-color: #000000; /* Dark background */
    color: white; /* White text */
    padding: 8px 12px; /* Padding for header */
    text-align: left; /* Align text to the left */
    font-size: 14px; /* Slightly bigger font size for header */
    border: 1px solid #ddd; /* Border around headers */
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0; /* Stick header to top */
    z-index: 1; /* Ensure the header stays above the body */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better separation */
  }
  
  /* Table cell styling */
  .stock-data-table-cell {
    padding: 5px; /* Consistent padding */
    font-size: 12px; /* Slightly smaller font size for data cells */
    border: 1px solid #ddd; /* Border around cells */
    text-align: left; /* Align text to the left */
    white-space: nowrap; /* Prevent wrapping of text */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Show ellipsis if the text overflows */
  }
  
  .pagination-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .pagination-button {
    padding-top: 0%;
    cursor: pointer;
    font-size: 18px;
    padding: 5px 5px;
    background-color: rgb(83, 83, 233);
    border: 1px solid #cccccc;
    border-radius: 5px;
  }
  
  .pagination-button:disabled {
    cursor: not-allowed;
    background-color: #f0f0f0;
    border-color: #ddd;
  }
  
  .pagination-link {
    cursor: pointer;
    text-decoration: underline;
    color: blue;
  }
  
  .pagination-link.disabled {
    cursor: not-allowed;
    text-decoration: none;
    color: grey;
  }
  
  .pagination-info {
    font-weight: bold;
    color: #333;
  }
  
  
  /* Loading message styling */
  .stock-table-container p {
    font-size: 14px;
    color: #555;
    text-align: center;
  }
  
  /* Container for the column selection dropdown */
  .column-selection {
    margin-bottom: 20px; /* Space below the dropdown */
    font-family: Arial, sans-serif; /* Clean font */
    display: flex;
    justify-content: space-between; /* Space between elements */
    align-items: center; /* Align elements vertically */
  }
  
  .column-selection .select-container {
    display: flex;
    align-items: center; /* Align label and select field */
  }
  
  .column-selection label {
    font-weight: bold;
    font-size: 16px;
    margin-right: 10px;
    color: #333;
  }
  
  .column-selection select {
    width: 250px; /* Adjust width as per your design */
    padding: 10px;
    font-size: 14px;
    background-color: #f5f5f5; /* Light grey background */
    border: 1px solid #4e4c4c; /* Grey border */
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .column-selection select:hover {
    border-color: #888; /* Darker border when hovered */
  }
  
  .column-selection select:focus {
    border-color: #5b9bd5; /* Blue border when focused */
    box-shadow: 0 0 5px rgba(91, 155, 213, 0.5); /* Subtle blue shadow */
  }
  
  
  .column-selection input:hover {
    border-color: #888;
  }
  
  .column-selection input:focus {
    border-color: #5b9bd5;
    box-shadow: 0 0 5px rgba(91, 155, 213, 0.5);
  }

  
  /* Expand/Collapse button styling */
  .expand-button {
    background-color: #f1f1f1;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    transition: background-color 0.3s ease;
  }
  
  .expand-button:hover {
    background-color: #e0e0e0;
  }

  .column-selection {
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
  }
  
  .column-selection label {
    font-weight: bold;
    margin-left: 20px;
    font-size: 16px;
    color: #333;
  }
  
  .column-selection select,
  .column-selection input {
    width: 200px;
    padding: 8px;
    font-size: 14px;
    background-color: #f5f5f5;
    border: 2px solid #aabcf0;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .column-selection select:hover,
  .column-selection input:hover {
    border-color: #888;
  }
  
  .column-selection select:focus,
  .column-selection input:focus {
    border-color: #5b9bd5;
    box-shadow: 0 0 5px rgba(91, 155, 213, 0.5);
  }
  
  .slidleft{
    align-items: left;
  }

  .pdfContainer-stock {
    position: relative; /* Ensures the dropdown is positioned relative to this container */
    display: flex;
    justify-content: flex-end; /* Aligns items to the right */
    width: 98%;
}

.generate-button-stock {
    margin-top: 10px;
    position: relative;
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    z-index: 2; /* Ensure button is on top of the dropdown */
}

.generate-button-stock:hover {
    background-color: #3eac59; /* Darker green for hover effect */
}

.dropdown-content-stock {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  padding: 5px 0;
}

.pdfContainer-stock:hover .dropdown-content-stock {
    display: block; /* Display the dropdown when the container is hovered */
}

.dropdown-content-stock button {
  width: 100%;
  padding: 10px;
  background-color: white;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.dropdown-content-stock button:hover {
    background-color: #b3b3b3; /* Darker color on hover */
}

.dropdown-content-stock button:focus {
    outline: none; /* Remove focus outline */
}

  

  
  /* Responsive design adjustments */
  @media (max-width: 1200px) {
    .stock-data-table {
      min-width: 600px; /* Reduce minimum width for medium-sized screens */
    }
  
    .pagination-button {
      font-size: 12px;
      padding: 6px 12px;
    }
  
  
    .expand-button {
      padding: 5px 8px;
    }
  }
  
  @media (max-width: 768px) {
    .stock-data-table {
      min-width: 100%; /* Full width for smaller screens */
      border: none; /* Remove border for better readability */
    }
  
    .stock-table-container {
      margin-left: 10px;
      margin-right: 10px;
    }
  
    .pagination-button {
      font-size: 14px;
      padding: 6px 12px;
      margin: 5px 0;
    }
  
    .pagination-info {
      font-size: 14px;
      margin-bottom: 10px;
    }
  
    .expand-button {
      padding: 5px 8px;
    }
  }
  
  @media (max-width: 480px) {
    /* Make sure table text and controls are readable on small devices */
    .stock-data-table {
      font-size: 10px;
    }
  
    .expand-button {
      padding: 4px 6px;
      font-size: 14px;
    }
  
    .stock-table-container {
      margin-left: 5px;
      margin-right: 5px;
    }
  
    .stock-data-table-header,
    .stock-data-table-cell {
      padding: 4px 6px;
    }
  
    .pagination-button {
      font-size: 12px;
      padding: 6px 12px;
    }
  }
  
/* Ensure proper alignment for the select dropdown and search input */
.column-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow the elements to wrap on smaller screens */
}

/* Positioning the search container initially */
.column-selection .search-container {
  position: relative;
  transition: all 0.3s ease-in-out;
  margin-top: 10px; /* Space between the select dropdown and search input */
}

/* When the screen width is smaller than 768px */
@media (max-width: 768px) {
  .column-selection .search-container {
    transform: translateY(20px) translateX(-20px); /* Slide down and shift left */
    margin-left: 0; /* Remove any extra left margin */
    width: 100%; /* Make the search input take full width */
  }

  /* Make the select container and search input stack vertically */
  
  .column-selection .search-container {
    margin: 0; /* Remove any extra margin */
    margin-left: 10px;
  }

 

  /* Optional: Adjust font sizes for smaller screens */
  .column-selection label {
    font-size: 14px;
    margin-left: 30px ;
  }
}

.stock-heading p {
  font-size: 14px;
  color: #555;
}

.total-stock-text {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}

.grand-total-text {
  font-size: 12px;
  color: #333;
  margin-top: 5px;
}

