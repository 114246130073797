.admin-login-container {
  width: 350px;
  margin: 100px auto;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.admin-login-container h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-size: 24px;  /* Adjust this size to fit your design */
  display: flex;
  align-items: center;  /* Vertically center the logo and text */
  justify-content: center;  /* Center logo and text horizontally */
}

.admin-login-container h2 img {
  width: 50px;  /* Set the logo size */
  margin-right: 10px;  /* Space between the logo and the text */
  vertical-align: middle;  /* Align logo vertically with the text */
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  color: #555;
}

.input-group input {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
}

.input-group input:focus {
  outline: none;
  border-color: #007bff;
}

.login-button-admin {
  width: 100%;
  padding: 12px;
  background-color: #4064a1;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button-admin:hover {
  background-color: #0056b3;
}

.login-button-admin:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
}
